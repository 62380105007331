import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgOtpInputModule } from 'ng-otp-input';
import { CountdownComponent, CountdownConfig, CountdownEvent, CountdownModule } from 'ngx-countdown';
import { CustomValidators } from '../../core/validators/custom.validator';
import { AuthService } from '../../services/auth.service';
import { DataService } from '../../services/data.service';
import { UtilityService } from '../../services/utils.service';
import { ILoginWithOtpPayload, ILoginWithPwdPayload, IOtpToEmailPayload, IAdminOtpResponse } from '../../interfaces/login.interface';
import { IRestResponse } from '../../core/interface/response.interface';
import { PwdInputComponent } from "../pwd-input/pwd-input.component";
import { sha256 } from 'js-sha256';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-login-password-less',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NgOtpInputModule, CountdownModule, PwdInputComponent],
  templateUrl: './login-password-less.component.html',
  styleUrl: './login-password-less.component.scss'
})
export class LoginPasswordLessComponent implements OnInit {
  adminLoginForm!: FormGroup;
  emailId: string = ''
  @ViewChild('cdTimer', { static: false }) cdTimer!: CountdownComponent;

  otpInputConfig = {
    length: 6,
    containerClass: 'text-center',
    allowNumbersOnly: true
  }
  otpInput = new FormControl('', [Validators.required, Validators.minLength(6)]);

  showOtpInput: 'otp' | 'none' | 'pwd' = 'none'
  isAdmin = false

  allowResendOtp = false
  countDownConfig: CountdownConfig = {
    demand: true,
    format: 'mm:ss',
    leftTime: 120
  }

  constructor(
    public utilityService: UtilityService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private dataService: DataService,
    private userService: UserService
  ) {
    if (this.authService.isLoggedIn) {
      if (this.authService.isSuperAdmin) {
        this.utilityService.onNavigate('admin/manage-company')
      }
      else if (this.authService.isUser) {
        if (this.authService.isSuperTenant && !this.dataService.getLocalStorageItem('selectedTenant')) {
          this.utilityService.onNavigate('manage-company')
        } else {
          this.utilityService.onNavigate('manage-campaigns')
        }
      }
    }
  }

  ngOnInit() {
    this.initLoginForm();
  }

  initLoginForm() {
    this.adminLoginForm = this.formBuilder.group({
      email: ['', [Validators.required, CustomValidators.email()]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      otp_id: ['', Validators.required],
      otp: this.otpInput
    })
  }

  onGetOtp() {
    if (this.emailId == this.adminLoginForm.controls['email'].value) {
      if (!this.isAdmin) {
        this.cdTimer.resume()
        this.showOtpInput = 'otp';
      } else {
        this.showOtpInput = 'pwd';
      }
    } else {
      this.getOtp()
    }
  }

  onLogin() {
    if (this.isAdmin) {
      if (this.adminLoginForm.controls['password'].valid) {
        const payload: ILoginWithPwdPayload = {
          username: this.adminLoginForm.value.email,
          password: sha256(this.adminLoginForm.value.password),
          grant_type: 'password'
        }
        this.getAccessToken(payload)
      } else {
        this.adminLoginForm.controls['password'].markAsTouched()
        return;
      }

    } else {
      if (this.adminLoginForm.controls['otp_id'].valid &&
        this.adminLoginForm.controls['otp'].valid) {
        const payload: ILoginWithOtpPayload = {
          username: this.adminLoginForm.value.email,
          grant_type: 'otp',
          otp: this.adminLoginForm.value.otp,
          otp_id: this.adminLoginForm.value.otp_id
        }

        this.getAccessToken(payload)
      } else {
        this.adminLoginForm.controls['otp'].markAsTouched()
        return;
      }
    }
  }

  getOtp() {
    this.otpInput.setValue('')
    let payload: IOtpToEmailPayload = {
      email: this.adminLoginForm.value.email,
      otp_channel: 'Email'
    }
    this.authService.sendLoginOtpToEmail(payload).subscribe((res: IRestResponse<string | IAdminOtpResponse>) => {
      if (typeof (res.response) == 'object' && res.response.is_admin) {
        this.showOtpInput = 'pwd'
        this.isAdmin = true
      } else {
        this.showOtpInput = 'otp'
        this.isAdmin = false
        this.adminLoginForm.controls['otp_id'].setValue(res.response)
        if (this.emailId == '') {
          this.cdTimer.begin()
        } else {
          this.cdTimer.restart();
          this.cdTimer.begin()
        }
        this.allowResendOtp = false
      }
      this.emailId = this.adminLoginForm.controls['email'].value
    })
  }

  onEditEmailId() {
    if (!this.isAdmin) {
      this.cdTimer.pause()
    }
    this.showOtpInput = 'none'
  }

  handleCDTimerEvent(event: CountdownEvent) {
    if (event.action == 'done') {
      this.allowResendOtp = true
    }
  }
  onRegister() {
    this.utilityService.onNavigate('/register')
  }

  getAccessToken(payload: ILoginWithOtpPayload | ILoginWithPwdPayload) {

    this.authService.login(payload).subscribe((res: any) => {
      if (res) {
        this.dataService.saveLoginResponse(res, this.adminLoginForm.value.email);
        if (this.authService.isSuperAdmin) {
          this.utilityService.onNavigate('admin/manage-company')
        } else if (this.authService.isUser) {
          this.checkIfWabaVerified()
        }
      }
    })
  }

  checkIfWabaVerified() {
    this.userService.getWabaDetails(this.authService.tenantId).subscribe(res => {
      if (res && res.response) {
        this.userService.wabaInfo = res.response
        this.cdTimer.stop()
        if (this.authService.isSuperTenant) {
          this.utilityService.onNavigate('manage-company')
        }
        this.utilityService.onNavigate('manage-campaigns')
      }
    })
  }

}
