import { provideHttpClient } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { take } from 'rxjs';
import { routes } from './app.routes';
import { InitializeService } from './services/initialize-user-details.service';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'top'
      }),
    ),
    provideHttpClient(),
    provideAnimationsAsync(),
    {
      provide: APP_INITIALIZER,
      useFactory: (initializeService: InitializeService) => {
        return () => {
          initializeService.initializeUserWabaDetails();
          return initializeService.initStatusObs.pipe(take(1))
        }
      },
      multi: true,
      deps: [InitializeService],
    }
  ]
};
