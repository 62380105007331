import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AuthService } from '../services/auth.service';
import { selectedTenantService } from '../services/selectedTenant.service';
import { InitializeService } from '../services/initialize-user-details.service';
import { map, Observable, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AllUserGuard implements CanActivate {
  constructor(public auth: AuthService, public router: Router,
    private selectedTenant: selectedTenantService, public initService: InitializeService) { }
    
  canActivate(): Observable<boolean> {
    return this.initService.initStatusObs.pipe(take(1), map((initValue: '' | 'completed') => {
      if (this.auth.isSubTenant || this.auth.isTenant || this.auth.isSuperTenant) {
        if (this.auth.isSuperTenant && !this.selectedTenant.selectedTenantInfo) {
          this.router.navigate(['/manage-company']);
          return false;
        }
        return true;
      }
      this.router.navigate(['404']);
      return false;
    }))

  }
}
