<nav class="navbar w-100 p-0">
  <div class="flex-fill">
    <div class="text-center menu-top-header bg-white p-3">
      <a [routerLink]="['/']" routerLinkActive="router-link-active"><img src="/assets/images/logo.png" class="logo"
          alt="logo" loading="eager"></a>
    </div>
    <div class="justify-content-between pt-3">
      <ul class="navbar-nav">
        @if(authService.isUser){
        <li class="nav-item" *ngIf="authService.isSuperTenant && !selectedTenant.selectedTenantInfo">
          <a routerLink="/manage-company" routerLinkActive="active">Companies</a>
        </li>

        @if((authService.isTenant && userService.wabaInfo && !userService.wabaInfo.waba_verified)||
        (authService.isSuperTenant && selectedTenant.selectedTenantInfo &&
        selTenWabaInfo && !selTenWabaInfo.waba_verified) ){
        <li class="nav-item">
          <a routerLink="/sender" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }">Sender</a>
        </li>
        }
        @if(((authService.isTenant || authService.isSubTenant) && userService.wabaInfo )
        || (authService.isSuperTenant && selectedTenant.selectedTenantInfo && selTenWabaInfo)){
        <li class="nav-item">
          <a routerLink="/manage-leads" routerLinkActive="active">Leads</a>
        </li>
        <li class="nav-item ">
          <a routerLink="/manage-templates" routerLinkActive="active">Templates</a>
        </li>
        <li class="nav-item">
          <a routerLink="/manage-campaigns" routerLinkActive="active">Campaigns</a>
        </li>
        }
        }@else if(authService.isSuperAdmin){
        <li class="nav-item">
          <a routerLink="/admin/manage-company" routerLinkActive="active">Companies</a>
        </li>
        }

      </ul>

    </div>
  </div>
</nav>
<div class="top-header d-flex ">
  @if(authService.isUser && !authService.isSubTenant){
  <a class="creditPoints" routerLink="credit-points">
    <img src="/assets/images/star.png" alt="">
    <h5 class="mb-0">2000</h5>
  </a>
  }
  @if(authService.isUser && authService.isSuperTenant ){
  <div class=" text-center text-lg-end subtenants">
    <div class="position-relative pointer font-14 d-flex align-items-center gap-2">
      <app-select-subtenant></app-select-subtenant>
    </div>
  </div>
  }
  <div class=" text-center text-lg-end userSettings">
    <div class="position-relative pointer" #dropdownRef (click)="dropdownToggleEvent($event)">
      <a class="nav-link dropdown-toggle font-14">
        {{dataService.getLocalStorageItem('tenant_name')}}
        <!-- <img src="/assets/images/user.png" class="pe-2 userImg" alt="user" height="26px"> -->
      </a>
    </div>
    <ul class="dropdown-menu font-14" [ngClass]="isDropdown?'show' :'hide'">
      <li>
        @if(authService.isUser){
        <a class="dropdown-item" routerLink='/my-profile'>My Profile</a>
        @if(authService.isTenant){
        <a class="dropdown-item" routerLink='subscribe/transactions'>Subscription Transactions</a>
        }
        @if(authService.isSuperTenant && !selectedTenant.selectedTenantInfo){
        <a class="dropdown-item" routerLink='manage-company/subscribe/transactions'>Subscription Transactions</a>
        }
        @if(!authService.isSubTenant){
        <a class="dropdown-item" routerLink='credit-points/transactions'>Credit Point Transactions</a>
        }
        }
        <a class="dropdown-item pointer" (click)="onLogout()">Logout</a>
      </li>
    </ul>
  </div>
</div>