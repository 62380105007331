import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';
import { selectedTenantService } from '../services/selectedTenant.service';
import { map, take } from 'rxjs';
import { InitializeService } from '../services/initialize-user-details.service';

export const notSubtenantGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService)
  const router = inject(Router)
  const selectedTenant = inject(selectedTenantService)
  const initService = inject(InitializeService)

  return initService.initStatusObs.pipe(take(1), map((initValue: '' | 'completed') => {
    if (authService.isSuperTenant || authService.isTenant) {
      if (authService.isSuperTenant && !selectedTenant.selectedTenantInfo) {
        router.navigate(['manage-company']);
      }
      return true;
    }
    router.navigate(['404']);
    return false;
  }))
};
