import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, filter } from 'rxjs';
import { AuthService } from './auth.service';
import { DataService } from './data.service';
import { selectedTenantService } from './selectedTenant.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class InitializeService {

  private initStatusSub = new BehaviorSubject<"" | 'completed'>('');
  readonly initStatusObs = this.initStatusSub.asObservable().pipe(filter(status => status == 'completed'));

  constructor(private router: Router, private userService: UserService,
    private authService: AuthService, private selectTenService: selectedTenantService,
    private dataService: DataService) { }

  initializeUserWabaDetails() {
    if (this.authService.isLoggedIn && this.authService.isUser) {
      let selectedTenant = this.dataService.getLocalStorageItem('selectedTenant')
      if (this.authService.isSuperTenant && selectedTenant) {
        this.userService.getWabaDetails(this.authService.tenantId).subscribe((res) => {
          this.userService.wabaInfo = res.response
        })
        
        this.userService.getWabaDetails(selectedTenant.value).subscribe((res) => {
          this.selectTenService.selectedTenantInfo = selectedTenant
          this.selectTenService.selectedTenWabaInfo = res.response
          this.initStatusSub.next('completed')
        }, ((err) => {
          this.router.navigate(['something-went-wrong'])
          this.initStatusSub.next('completed')
        }))
      } else {
        this.userService.getWabaDetails(this.authService.tenantId).subscribe((res) => {
          this.userService.wabaInfo = res.response
          this.initStatusSub.next('completed')
        }, ((err) => {
          this.router.navigate(['something-went-wrong'])
          this.initStatusSub.next('completed')
        }))

      }
    } else {
      this.initStatusSub.next('completed')
    }
  }

}
