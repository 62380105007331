import { CommonModule } from '@angular/common';
import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { EUserType } from '../../enums/user.enum';
import { IIsWabaVerifiedResp } from '../../interfaces/user.interface';
import { AuthService } from '../../services/auth.service';
import { DataService } from '../../services/data.service';
import { selectedTenantService } from '../../services/selectedTenant.service';
import { UserService } from '../../services/user.service';
import { SelectTenantComponent } from '../select-tenant/select-tenant.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, RouterModule, ReactiveFormsModule, SelectTenantComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  isDropdown: boolean = false;

  EUserType = EUserType

  selTenWabaInfo: null | IIsWabaVerifiedResp = null

  @ViewChild('dropdownRef', { static: false }) dropdownRef?: ElementRef;
  constructor(public dataService: DataService,
    public userService: UserService,
    public authService: AuthService,
    private renderer: Renderer2,
    private router:Router,
    public selectedTenant: selectedTenantService) {
    this.renderer.listen('window', 'click', (event: Event) => {
      if (this.isDropdown && !this.dropdownRef?.nativeElement.contains(event.target)) {
        this.isDropdown = false;
      }
    })
  }

  ngOnInit() {
    this.selTenWabaInfo = this.selectedTenant.selectedTenWabaInfo
    this.watchSubTenant()
  }

  onLogout(): void {
    this.userService.wabaInfo = null
    this.selectedTenant.resetSelectedTenant()
    this.authService.forceLogout()
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['login']);
    });
  }

  dropdownToggleEvent(event: Event) {
    event.preventDefault();
    this.isDropdown = !this.isDropdown;
  }

  watchSubTenant() {
    this.selectedTenant.selectedTenantWabaSub.subscribe(res => {
      this.selTenWabaInfo = res
    })
  }

}
