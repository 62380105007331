import { Component } from '@angular/core';
import { UserService } from '../../services/user.service';
import { ThWithInfoComponent } from "../th-with-info/th-with-info.component";
import { RouterLink } from '@angular/router';
import { selectedTenantService } from '../../services/selectedTenant.service';
import { AuthService } from '../../services/auth.service';
import { IIsWabaVerifiedResp } from '../../interfaces/user.interface';
@Component({
  selector: 'app-subscription-banner',
  standalone: true,
  imports: [ThWithInfoComponent, RouterLink],
  templateUrl: './subscription-banner.component.html',
  styleUrl: './subscription-banner.component.scss'
})
export class SubscriptionBannerComponent {
  message = ""
  trialPeriod: boolean = false
  constructor(public userService: UserService, public authService: AuthService, public selectedTenant: selectedTenantService) { }
  ngOnInit() {
    if (this.authService.isSuperTenant) {
      this.watchSubTenantWabaInfo()
      this.watchTenantWabaInfo()
    } else {
      this.watchTenantWabaInfo()
    }

  }

  watchTenantWabaInfo() {
    this.userService.tenantWabaInfoSubject.subscribe((res: IIsWabaVerifiedResp | null) => {
      if (res) {
        let wabaInfo = res
        if (wabaInfo && !wabaInfo.is_subscribed) {
          this.message = "Your free trial has expired."
          this.trialPeriod = wabaInfo.is_in_trial_period
          if (this.trialPeriod) {
            this.message = `You have ${wabaInfo.trial_days_left} ${wabaInfo.trial_days_left == 1 ? ' day' : ' days'} of your free trial period left.`
          }
        } else {
          this.message = ''
        }
      }

    })
  }

  watchSubTenantWabaInfo() {
    this.selectedTenant.selectedTenantWabaSub.subscribe((res: IIsWabaVerifiedResp | null) => {
      if (res && !res.is_subscribed) {
        this.trialPeriod = res.is_in_trial_period
        this.message = this.trialPeriod
          ? `Selected Company has ${res.trial_days_left} ${res.trial_days_left == 1 ? ' day' : ' days'} of your free trial period left.`
          : "Selected Company's free trial has expired."
      } else {
        this.message = ''
      }
    })
  }

}
