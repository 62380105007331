<div class="footer-wrapper">
    <div class="address flex-sm-row flex-column">
        <address class="order-2 order-md-1 pt-4 py-md-0">
            <div class="d-flex  align-items-center">
                <mat-icon aria-hidden="true" aria-label="email" fontIcon="mail"></mat-icon>
                <span class="ps-2 pe-3">wecare&#64;leadstacts.com</span>
                <app-clip-board text="wecare@leadstacts.com"></app-clip-board>
            </div>
            <div class="d-flex align-items-center">
                <mat-icon aaria-hidden="true" aria-label="call" fontIcon="call"></mat-icon>
                <span class="ps-2 pe-3">+91 9008170212</span>
                <app-clip-board text="+919008170212"></app-clip-board>
            </div>
            <br>
            <div class="d-flex">
                <mat-icon aria-hidden="true" aria-label="location" fontIcon="location_on"></mat-icon>
                <span class="ps-2">
                    A2-3071 Casa Paradiso 3, Sobha City, <br>
                    RK Hegde Nagar, Kothanur, Bangalore North,<br>
                    Bangalore- 560077, Karnataka<br>
                </span>
            </div>

        </address>
        <div class="logo-img align-self-center order-1 order-sm-2">
            <a href="https://www.leadstacts.com/" target="_blank" rel="noopener noreferrer">
                <img src="/assets/images/logo.png" alt="https://www.leadstacts.com/" width="300px">
            </a>
        </div>
    </div>
    <div class="link-copyright flex-lg-row flex-md-column flex-column">
        <span class="order-2 order-lg-1">© Copyrights {{currentDate | date:'yyyy'}}. All rights reserved.</span>
        <div class="order-1 order-lg-2 footer-links flex-column flex-md-row">
            <a routerLink="/privacy-policy" routerLinkActive="active">Privacy Policy</a> <span
                class="d-none d-md-block">|</span>
            <a routerLink="/terms-conditions" routerLinkActive="active">Terms Condition</a> <span
                class="d-none d-md-block">|</span>
            <a routerLink="/refund-policy" routerLinkActive="active">Refund Policy</a> <span
                class="d-none d-md-block">|</span>
            <a routerLink="/register" routerLinkActive="active">Register</a> <span class="d-none d-md-block">|</span>
            <a routerLink="/login" routerLinkActive="active">Login</a>
        </div>
    </div>
</div>