import { Component, Input, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { ClipboardModule } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-clip-board',
  standalone: true,
  imports: [MatIconModule, MatTooltipModule, ClipboardModule],
  templateUrl: './clip-board.component.html',
  styleUrl: './clip-board.component.scss'
})
export class ClipBoardComponent {
  @Input() label = '';
  @Input({ required: true }) text!: string
  @ViewChild('myTooltip') tooltip!: MatTooltip

  copyToClipBoard($event: boolean) {
    if ($event) {
      this.tooltip.disabled = false
      this.tooltip.show()
      let timer = setTimeout(() => {
        this.tooltip.hide()
        this.tooltip.disabled = true
        clearTimeout(timer)
      }, 1000)
    }
  }
}
