@if(message){
<div class="banner">
    <div class="flex align-items-center">
        <p>{{message}}</p>

        @if(trialPeriod){
        <app-th-with-info tooltipText="During trial period you will have limited access to our features."></app-th-with-info>
        }@else {
            @if(authService.isTenant){
                &nbsp; <a [routerLink]="['subscribe']" > Subscribe</a>
            }@else if(authService.isSuperTenant){
                @if(selectedTenant.selectedTenWabaInfo){
                    @if(!selectedTenant.selectedTenWabaInfo.is_subscribed){
                        &nbsp; <a [routerLink]="['subscribe']"> Setup Subscription</a>
                    }
                }@else {
                    @if(!userService.wabaInfo?.is_in_trial_period){
                        &nbsp; <a [routerLink]="['manage-company/packages']"> Choose a Package</a>
                    }
                }
            }
        }

    </div>
</div>
}