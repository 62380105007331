import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, GuardResult, MaybeAsync, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { InitializeService } from '../services/initialize-user-details.service';
import { map, Observable, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(public initService: InitializeService, private auth: AuthService, private router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.initService.initStatusObs.pipe(take(1), map((initValue: '' | 'completed') => {
      if (this.auth.isLoggedIn) {
        return true;
      } else {
        this.router.navigate(['login']);
        return false;
      }
    }))

  }
}
