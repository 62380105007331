import { CanActivateFn, Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { selectedTenantService } from '../services/selectedTenant.service';
import { InitializeService } from '../services/initialize-user-details.service';
import { map, take } from 'rxjs';

export const allowMessagingGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService)
  const userService = inject(UserService)
  const selectedTenant = inject(selectedTenantService)
  const router = inject(Router)
  const initService = inject(InitializeService)

  return initService.initStatusObs.pipe(take(1), map((initValue: '' | 'completed') => {

    if ((!authService.isSuperTenant && userService.wabaInfo && userService.wabaInfo.messaging_service_exists)
      || (selectedTenant.selectedTenWabaInfo && selectedTenant.selectedTenWabaInfo.messaging_service_exists)) {
      return true;
    } else {
      router.navigate(['sender/sender-no-list'])
      return false
    }
  }))

};
