import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { selectedTenantService } from '../services/selectedTenant.service';
import { InitializeService } from '../services/initialize-user-details.service';
import { map, take } from 'rxjs';
import { UserService } from '../services/user.service';

export const superTenantGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService)
  const router = inject(Router)
  const userService = inject(UserService)
  const selectedTenantSer = inject(selectedTenantService)
  const initService = inject(InitializeService)
  return initService.initStatusObs.pipe(take(1), map((initValue: '' | 'completed') => {
    if (authService.isSuperTenant) {
      if (!selectedTenantSer.selectedTenantInfo) {
        // if (!userService.wabaInfo?.is_in_trial_period) {
        //   router.navigate(['packages']);
        //   return false
        // }
        return true;
      }
      router.navigate(['/manage-campaigns']);
      return false
    } else {
      router.navigate(['404']);
      return false;
    }
  }))

};