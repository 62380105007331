<div class="d-flex align-items-center gap-2">
    @if (selectedTenantService.selectedTenantInfo) {
    <button type="button" class="btn cancel-btn" (click)="goBackToAdmin()">
        <img src="/assets/images/arrow.png" alt="Back to Admin" loading="eager">
        Back To Admin</button>
    }
    <mat-form-field style="width:250px;">
        <mat-label>Select a company </mat-label>
        <input type="text" name="subtenant" matInput [formControl]="tenantSelectControl" required
            [matAutocomplete]="selectTenant" >
        <mat-autocomplete requireSelection #selectTenant="matAutocomplete" [displayWith]="utilityService.dropDownDisplayOption">
            @if(subTenantList.length>0){
            @for (subtenant of subTenantList; track subtenant) {
            <mat-option [value]="subtenant">{{subtenant.label}}</mat-option>
            }
            }@else {
            <mat-option value="" disabled>No companies available.</mat-option>
            }
        </mat-autocomplete>
    </mat-form-field>
</div>