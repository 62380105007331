import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, filter, map, throwError } from 'rxjs';
import { Environment } from '../../environments/environment';
import { IRestResponse } from '../core/interface/response.interface';
import { ICreateSenderPayload, IIsWabaVerifiedResp } from '../interfaces/user.interface';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private tenantWabaInfo = new BehaviorSubject<IIsWabaVerifiedResp | null>(null)
  public readonly tenantWabaInfoSubject = this.tenantWabaInfo.asObservable().pipe(filter(info => info != null))

  constructor(private restService: RestService) {
  }

  set wabaInfo(data: IIsWabaVerifiedResp | null) {
    this.tenantWabaInfo.next(data)
  }

  get wabaInfo() {
    return this.tenantWabaInfo.getValue()
  }

  reloadWabaInfo(){
    let wabaInfo = this.wabaInfo
    this.tenantWabaInfo.next(wabaInfo)
  }

  public getWabaDetails(companyId: string): Observable<IRestResponse<IIsWabaVerifiedResp>> {
    return this.restService.get(Environment.UrlPath + `/company/${companyId}/status`, null, true)
      .pipe(
        map(
          (res: any) => res),
        catchError((error) => throwError(error))
      )
  }

  public createSender(payload: ICreateSenderPayload): Observable<IRestResponse<any>> {
    return this.restService
      .post(Environment.UrlPath + `/twilio/sender`, payload, false, false)
      .pipe(
        map((res: any) => res),
        catchError((error) => throwError(error))
      );
  }
}
