import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

export const transactionGuard: CanActivateFn = (route, state) => {
  const auth = inject(AuthService)
  const router = inject(Router)
  if (auth.isTenant || auth.isSuperTenant) {
    return true;
  }
  router.navigate(['404']);
  return false;
};
