import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-something-went-wrong',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './something-went-wrong.component.html',
  styleUrl: './something-went-wrong.component.scss'
})
export class SomethingWentWrongComponent {
  constructor(private router: Router) { }
  refresh(): void {
    this.router.navigate(['/']).then(() => {
      window.location.reload();
    });
  }
}
