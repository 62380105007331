import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { selectedTenantService } from '../services/selectedTenant.service';
import { map, take } from 'rxjs';
import { InitializeService } from '../services/initialize-user-details.service';

export const subscribeGuard: CanActivateFn = (route, state) => {
  const userService = inject(UserService)
  const authService = inject(AuthService)
  const selectedTenantSer = inject(selectedTenantService)
  const router = inject(Router)
  const initService = inject(InitializeService)

  return initService.initStatusObs.pipe(take(1), map((initValue: '' | 'completed') => {
    if (!authService.isSuperTenant && userService.wabaInfo) {
      if (!userService.wabaInfo.is_subscribed && !userService.wabaInfo.is_in_trial_period) {
        router.navigate(['subscribe'], { replaceUrl: true })
      }
      return true
    } else if (selectedTenantSer.selectedTenantInfo && selectedTenantSer.selectedTenWabaInfo) {
      if (!selectedTenantSer.selectedTenWabaInfo.is_subscribed && !selectedTenantSer.selectedTenWabaInfo.is_in_trial_period) {
        router.navigate(['subscribe'], { replaceUrl: true })
      }
      return true
    } else {
      router.navigate(['404'])
      return false
    }
  }))


}
