import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { map, Observable, take } from "rxjs";
import { AuthService } from "../services/auth.service";
import { UserService } from "../services/user.service";
import { selectedTenantService } from "../services/selectedTenant.service";
import { InitializeService } from "../services/initialize-user-details.service";

@Injectable({
    providedIn: 'root'
})

export class WabaGuard implements CanActivate {
    constructor(private userService: UserService, private selectedTenant: selectedTenantService, private authService: AuthService, private router: Router, public initService: InitializeService) { }
    canActivate(): Observable<boolean> | boolean {
        return this.initService.initStatusObs.pipe(take(1), map((initValue: '' | 'completed') => {
            if ((!this.authService.isSuperTenant && this.userService.wabaInfo && this.userService.wabaInfo.waba_verified)
                || (this.selectedTenant.selectedTenWabaInfo && this.selectedTenant.selectedTenWabaInfo.waba_verified)) {
                return true
            } else {
                this.router.navigate(['sender/unverified-waba'])
                return false
            }
        }))

    }
}