import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon'
import { DatePipe } from '@angular/common';
import { ClipBoardComponent } from "../clip-board/clip-board.component";
@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [RouterModule, MatIconModule, DatePipe, ClipBoardComponent],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  currentDate = new Date()
}
