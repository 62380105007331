import { Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
@Component({
  selector: 'app-th-with-info',
  standalone: true,
  imports: [MatTooltipModule],
  templateUrl: './th-with-info.component.html',
  styleUrl: './th-with-info.component.scss'
})
export class ThWithInfoComponent {
  @Input() heading!: string
  @Input() tooltipText!:string
}
